import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store";
import ConsultantList from "./Pages/ConsultantList";
import ConsultantForm from "./Pages/ConsultantForm";
import ConsultantAvailability from "./Pages/ConsultantAvailability";
import Login from "./Pages/Login";
import PrivateRoute from "./components/PrivateRoute";
import Layout from "./components/Layout";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route element={<PrivateRoute />}>
            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route path="/" element={<ConsultantList />} />
              <Route path="/add" element={<ConsultantForm />} />
              <Route path="/edit/:id" element={<ConsultantForm />} />
              <Route
                path="/consultant/:id/availability"
                element={<ConsultantAvailability />}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
