import { configureStore } from "@reduxjs/toolkit";
import { consultingServiceApi } from "./Api/consultingServiceApi";
import { authServiceApi } from "./Api/authServiceApi";

const store = configureStore({
  reducer: {
    [consultingServiceApi.reducerPath]: consultingServiceApi.reducer,
    [authServiceApi.reducerPath]: authServiceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(consultingServiceApi.middleware)
      .concat(authServiceApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
