import React from "react";
import Sidebar from "./Sidebar";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../util/AuthUtils";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    removeToken();
    localStorage.removeItem("userDetails");
    navigate("/login");
  };

  return (
    <Box display="flex">
      <Sidebar />
      <Box flex="1" padding="16px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Typography variant="h6">Dashboard</Typography>
          <Button variant="outlined" color="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </Box>

        {children}
      </Box>
    </Box>
  );
};

export default Layout;
