import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { removeToken, setToken } from "../../util/AuthUtils";
const API_BASE_URL = process.env.REACT_APP_API_USER_SERVICE_URL || "";

export const authServiceApi = createApi({
  reducerPath: "authServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    generateOtp: builder.mutation<void, { mobileNo: string; platform: string }>(
      {
        query: ({ mobileNo, platform }) => ({
          url: "/login/generate-otp",
          method: "GET",
          params: { mobileNo, platform },
        }),
      }
    ),

    login: builder.mutation<
      { token: string },
      {
        mobileNo: string;
        otpCode: string;
        fcmToken: string;
        deviceId: string;
        platform: string;
      }
    >({
      query: (payload) => ({
        url: "/login/login",
        method: "POST",
        body: payload,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          setToken(data.token);
        } catch {
          console.error("Failed to log in");
        }
      },
    }),

    logout: builder.mutation<
      void,
      { mobileNo: string; fcmToken: string; deviceId: string }
    >({
      query: (payload) => ({
        url: "/login/logout",
        method: "POST",
        body: payload,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          removeToken();
        } catch {
          console.error("Failed to log out");
        }
      },
    }),
  }),
});

export const { useGenerateOtpMutation, useLoginMutation, useLogoutMutation } =
  authServiceApi;
