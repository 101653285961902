import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetConsultantsQuery,
  useUpdateConsultantMutation,
  useDeleteConsultantMutation,
} from "../Redux/Api/consultingServiceApi";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Rating from "@mui/material/Rating";

interface Consultant {
  id: number;
  name: string;
  email: string;
  specialization: string;
  rating: number;
}

const ConsultantList: React.FC = () => {
  const { data: consultants = [], isLoading } = useGetConsultantsQuery();
  const [updateConsultant] = useUpdateConsultantMutation();
  const [deleteConsultant] = useDeleteConsultantMutation();
  const navigate = useNavigate();

  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [editConsultant, setEditConsultant] = useState<Consultant | null>(null);

  if (isLoading) return <p>Loading consultants...</p>;

  const handleEditClick = (consultant: Consultant) => {
    setEditConsultant(consultant);
    setEditDialogOpen(true);
  };

  const handleDeleteClick = async (consultantId: number) => {
    if (window.confirm("Are you sure you want to delete this consultant?")) {
      await deleteConsultant(consultantId);
    }
  };

  const handleEditSave = async () => {
    if (editConsultant) {
      const { id, ...data } = editConsultant;
      await updateConsultant({ id, data }).unwrap();
      setEditDialogOpen(false);
      setEditConsultant(null);
    }
  };

  const handleEditChange = (
    field: keyof Consultant,
    value: string | number
  ) => {
    if (editConsultant) {
      setEditConsultant({ ...editConsultant, [field]: value });
    }
  };

  return (
    <div>
      <h1>Consultant List</h1>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Specialization</TableCell>
            <TableCell>Rating</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {consultants.map((consultant) => (
            <TableRow key={consultant.id}>
              <TableCell>{consultant.name}</TableCell>
              <TableCell>{consultant.email}</TableCell>
              <TableCell>{consultant.specialization}</TableCell>
              <TableCell>
                <Rating value={consultant.rating} precision={0.5} readOnly />
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(`/consultant/${consultant.id}/availability`)
                  }
                >
                  Set Availability
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleEditClick(consultant)}
                  style={{ marginLeft: 8 }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleDeleteClick(consultant.id)}
                  style={{ marginLeft: 8 }}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={isEditDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit Consultant</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={editConsultant?.name || ""}
            onChange={(e) => handleEditChange("name", e.target.value)}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Email"
            value={editConsultant?.email || ""}
            onChange={(e) => handleEditChange("email", e.target.value)}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Specialization"
            value={editConsultant?.specialization || ""}
            onChange={(e) => handleEditChange("specialization", e.target.value)}
            fullWidth
            margin="dense"
          />
          <div style={{ display: "flex", alignItems: "center", marginTop: 16 }}>
            <span style={{ marginRight: 8 }}>Rating:</span>
            <Rating
              value={editConsultant?.rating || 0}
              precision={0.5}
              onChange={(e, newValue) =>
                handleEditChange("rating", newValue || 0)
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConsultantList;
