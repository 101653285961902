import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Availability, Consultant } from "../types";
import { getToken } from "../../util/AuthUtils";

export const consultingServiceApi = createApi({
  reducerPath: "consultingServiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Consultant", "Availability"],
  endpoints: (builder) => ({
    getConsultants: builder.query<Consultant[], void>({
      query: () => "consultants",
      transformResponse: (response: any) => response.result || [],
      providesTags: ["Consultant"],
    }),
    addConsultant: builder.mutation<void, Partial<Consultant>>({
      query: (consultant) => ({
        url: "consultants",
        method: "POST",
        body: consultant,
      }),
      invalidatesTags: ["Consultant"],
    }),
    updateConsultant: builder.mutation<
      void,
      { id: number; data: Partial<Consultant> }
    >({
      query: ({ id, data }) => ({
        url: `consultants/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Consultant"],
    }),
    deleteConsultant: builder.mutation<void, number>({
      query: (id) => ({
        url: `consultants/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Consultant"],
    }),
    getAvailabilityByConsultantId: builder.query<Availability[], number>({
      query: (consultantId) => `availability/${consultantId}`,
      transformResponse: (response: any) => response.result || [],
      providesTags: ["Availability"],
    }),
    setAvailability: builder.mutation<
      void,
      { consultantId: number; availabilityData: Availability[] }
    >({
      query: ({ consultantId, availabilityData }) => ({
        url: `availability/${consultantId}`,
        method: "POST",
        body: availabilityData,
      }),
      invalidatesTags: ["Availability"],
    }),
  }),
});

export const {
  useGetConsultantsQuery,
  useAddConsultantMutation,
  useUpdateConsultantMutation,
  useDeleteConsultantMutation,
  useGetAvailabilityByConsultantIdQuery,
  useSetAvailabilityMutation,
} = consultingServiceApi;
