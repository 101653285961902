import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import {
  useAddConsultantMutation,
  useUpdateConsultantMutation,
  useGetConsultantsQuery,
} from "../Redux/Api/consultingServiceApi";
import { Consultant } from "../Redux/types";

const ConsultantForm: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEditing = Boolean(id);
  const { data: consultants = [] } = useGetConsultantsQuery();
  const [addConsultant] = useAddConsultantMutation();
  const [updateConsultant] = useUpdateConsultantMutation();
  const [consultant, setConsultant] = useState<Partial<Consultant>>({
    name: "",
    email: "",
    specialization: "",
    rating: 0,
  });

  useEffect(() => {
    if (isEditing) {
      const existingConsultant = consultants.find((c) => c.id === Number(id));
      if (existingConsultant) setConsultant(existingConsultant);
    }
  }, [isEditing, id, consultants]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setConsultant((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isEditing) {
      await updateConsultant({ id: Number(id), data: consultant });
    } else {
      await addConsultant(consultant);
    }
    navigate("/");
  };

  return (
    <div>
      <h1>{isEditing ? "Edit" : "Add"} Consultant</h1>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          value={consultant.name || ""}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={consultant.email || ""}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Specialization"
          name="specialization"
          value={consultant.specialization || ""}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Rating"
          name="rating"
          type="number"
          value={consultant.rating || ""}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
          inputProps={{ min: 0, max: 5, step: 0.1 }}
        />
        <Button type="submit" variant="contained" color="primary">
          {isEditing ? "Update" : "Add"} Consultant
        </Button>
      </form>
    </div>
  );
};

export default ConsultantForm;
