import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, TextField, Typography, Box, IconButton } from "@mui/material";
import { Edit, Save, Cancel } from "@mui/icons-material";
import {
  useGetAvailabilityByConsultantIdQuery,
  useSetAvailabilityMutation,
} from "../Redux/Api/consultingServiceApi";
import { Availability } from "../Redux/types";

const ConsultantAvailability: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const consultantId = Number(id);

  const { data: existingAvailability = [], isLoading } =
    useGetAvailabilityByConsultantIdQuery(consultantId);
  const [setAvailability] = useSetAvailabilityMutation();

  const [availabilities, setAvailabilities] = useState<Availability[]>([]);
  const [newAvailability, setNewAvailability] = useState<Availability>({
    date: "",
    startTime: "",
    endTime: "",
  });
  const [editingIndex, setEditingIndex] = useState<number | null>(null); // Tracks which availability is being edited

  useEffect(() => {
    setAvailabilities(existingAvailability);
  }, [existingAvailability]);

  const handleChange = (
    field: keyof Availability,
    value: string,
    index: number | null = null
  ) => {
    if (index === null) {
      setNewAvailability((prev) => ({ ...prev, [field]: value }));
    } else {
      const updatedAvailabilities = [...availabilities];
      updatedAvailabilities[index] = {
        ...updatedAvailabilities[index],
        [field]: value,
      };
      setAvailabilities(updatedAvailabilities);
    }
  };

  const handleAddAvailability = () => {
    if (
      newAvailability.date &&
      newAvailability.startTime &&
      newAvailability.endTime
    ) {
      setAvailabilities([...availabilities, newAvailability]);
      setNewAvailability({ date: "", startTime: "", endTime: "" });
    } else {
      alert("Please complete all fields before adding an availability slot.");
    }
  };

  const handleEditAvailability = (index: number) => {
    setEditingIndex(index);
  };

  const handleSaveEdit = () => {
    setEditingIndex(null);
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setAvailabilities(existingAvailability);
  };

  const handleSaveAll = async () => {
    await setAvailability({ consultantId, availabilityData: availabilities });
    alert("Availability saved successfully");
  };

  if (isLoading) return <p>Loading availability...</p>;

  return (
    <Box>
      <Typography variant="h4">Set Availability for Consultant</Typography>
      {availabilities.length ? (
        <Box mt={3} mb={3}>
          <Typography variant="h6">Existing Availability</Typography>
          {availabilities.map((availability, index) => (
            <Box key={index} mt={2} mb={2} border={1} padding={2}>
              {editingIndex === index ? (
                <>
                  <TextField
                    label="Date"
                    type="date"
                    value={availability.date}
                    onChange={(e) =>
                      handleChange("date", e.target.value, index)
                    }
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Start Time"
                    type="time"
                    value={availability.startTime}
                    onChange={(e) =>
                      handleChange("startTime", e.target.value, index)
                    }
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="End Time"
                    type="time"
                    value={availability.endTime}
                    onChange={(e) =>
                      handleChange("endTime", e.target.value, index)
                    }
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                  />
                  <IconButton onClick={handleSaveEdit} color="primary">
                    <Save />
                  </IconButton>
                  <IconButton onClick={handleCancelEdit} color="secondary">
                    <Cancel />
                  </IconButton>
                </>
              ) : (
                <>
                  <Typography>Date: {availability.date}</Typography>
                  <Typography>Start Time: {availability.startTime}</Typography>
                  <Typography>End Time: {availability.endTime}</Typography>
                  <IconButton
                    onClick={() => handleEditAvailability(index)}
                    color="primary"
                  >
                    <Edit />
                  </IconButton>
                </>
              )}
            </Box>
          ))}
        </Box>
      ) : (
        <Typography>No availability set yet.</Typography>
      )}

      <Box mt={4}>
        <Typography variant="h6">Add New Availability</Typography>
        <TextField
          label="Date"
          type="date"
          value={newAvailability.date}
          onChange={(e) => handleChange("date", e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Start Time"
          type="time"
          value={newAvailability.startTime}
          onChange={(e) => handleChange("startTime", e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Time"
          type="time"
          value={newAvailability.endTime}
          onChange={(e) => handleChange("endTime", e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddAvailability}
          sx={{ mt: 2 }}
        >
          Add Availability
        </Button>
      </Box>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleSaveAll}
        sx={{ mt: 4 }}
      >
        Save All Availability
      </Button>
    </Box>
  );
};

export default ConsultantAvailability;
