import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { setToken } from "../util/AuthUtils";
import {
  useGenerateOtpMutation,
  useLoginMutation,
} from "../Redux/Api/authServiceApi";

const Login: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [deviceId, setDeviceId] = useState<string>("");
  const [fcmToken, setFcmToken] = useState<string>("mock-fcm-token");
  const navigate = useNavigate();

  const [generateOtp] = useGenerateOtpMutation();
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();

  useEffect(() => {
    setDeviceId(uuidv4());
  }, []);

  const handleSendOtp = async () => {
    try {
      await generateOtp({
        mobileNo: phoneNumber,
        platform: "GROYYO_PLUS",
      }).unwrap();
      setIsOtpSent(true);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Failed to send OTP. Please try again.");
    }
  };

  const handleLogin = async () => {
    try {
      const response: any = await login({
        mobileNo: phoneNumber,
        otpCode: otp,
        fcmToken: fcmToken,
        deviceId: deviceId,
        platform: "GROYYO_PLUS",
      }).unwrap();

      if (response?.result?.token) {
        setToken(response.result.token);
        localStorage.setItem("isAuthenticated", "true");
        navigate("/");
      }
    } catch (error) {
      setErrorMessage("Invalid OTP. Please try again.");
    }
  };

  return (
    <div
      style={{ maxWidth: "400px", margin: "100px auto", textAlign: "center" }}
    >
      <h2>Login</h2>
      {errorMessage && (
        <div style={{ color: "red", marginBottom: "20px" }}>{errorMessage}</div>
      )}
      <form style={{ display: "flex", flexDirection: "column" }}>
        <label style={{ marginBottom: "10px" }}>
          Phone Number:
          <input
            type="text"
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            disabled={isOtpSent}
            style={{ padding: "8px", width: "100%", marginTop: "5px" }}
          />
        </label>
        {isOtpSent && (
          <label style={{ marginBottom: "10px" }}>
            OTP:
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              style={{ padding: "8px", width: "100%", marginTop: "5px" }}
            />
          </label>
        )}
        {!isOtpSent ? (
          <button type="button" onClick={handleSendOtp} style={buttonStyle}>
            Send OTP
          </button>
        ) : (
          <button
            type="button"
            onClick={handleLogin}
            style={buttonStyle}
            disabled={isLoginLoading}
          >
            {isLoginLoading ? "Logging in..." : "Login"}
          </button>
        )}
      </form>
    </div>
  );
};

const buttonStyle: React.CSSProperties = {
  padding: "10px",
  marginTop: "20px",
  backgroundColor: "#4CAF50",
  color: "white",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

export default Login;
