import React from "react";
import { NavLink } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  ListItemButton,
} from "@mui/material";

const Sidebar: React.FC = () => {
  return (
    <div
      style={{ width: "250px", padding: "16px", borderRight: "1px solid #ddd" }}
    >
      <Typography variant="h6" gutterBottom>
        Dashboard
      </Typography>
      <List>
        <ListItem disablePadding>
          <ListItemButton component={NavLink as any} to="/">
            <ListItemText primary="Consultant List" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={NavLink as any} to="/add">
            <ListItemText primary="Add Consultant" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
    </div>
  );
};

export default Sidebar;
